import _ from "lodash";
import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  _COLORS,
  _MESSAGES,
  _SWAL_ICONS,
  _ACTIONS,
} from "../../utils/_constants";
import { createToast } from "../../utils/toast";
import { RequestHelper } from "../../utils/requestHelper";
import { _STYLES, btn } from "../../utils/_style_constants";
import { UserContext } from "../contexts/UserContext";
import { getQueryParamsFromFormData } from "../../utils/formHelper";

export function AgenceForm({
  refreshListAgence,
  setShowModal,
  refresh,
  setRefresh,
  edit = null,
  setAgence,
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nom: edit ? edit.nom : "",
      raisonSociale: edit ? edit.raisonSociale : "",
      adresse: edit ? edit.adresse : "",
      ville: edit ? edit.ville : "",
      codePostal: edit ? edit.codePostal : "",
      tel: edit ? edit.tel : "",
      email: edit ? edit.email : "",
      carteT: edit ? edit.carteT : "",
      carteTDeliveredBy: edit ? edit.carteTDeliveredBy : "",
      rcs: edit ? edit.rcs : "",
      rcsVille: edit ? edit.rcsVille : "",
      "assureur.nom": edit ? edit.assureur?.nom : "",
      "assureur.adresse": edit ? edit.assureur?.adresse : "",
      "mediateur.nom": edit ? edit.mediateur?.nom : "",
      "mediateur.tel": edit ? edit.mediateur?.tel : "",
      "mediateur.email": edit ? edit.mediateur?.email : "",
      "mediateur.adresse": edit ? edit.mediateur?.adresse : "",
      "mediateur.site": edit ? edit.mediateur?.site : "",
      garantNom: edit ? edit.garantNom : "",
      garantAdresse: edit ? edit.garantAdresse : "",
      tvaIntra: edit ? edit.tvaIntra : "",
      nomGerant: edit ? edit.nomGerant : "",
      numeroAgence: edit ? edit.numeroAgence : "",
      numeroDernier: edit ? edit.numeroDernier : "",
      statutRepresentant: edit ? edit.statutRepresentant : "",
      codeApe: edit ? edit.codeApe : "",
    },
  });
  const { user, token } = React.useContext(UserContext);
  const requestHelper = new RequestHelper(token);
  const [part1display, setPart1Display] = React.useState("flex");
  const [part2display, setPart2Display] = React.useState("none");
  const [statutJuridiques, setStatutJuridiques] = React.useState([]);
  const [selectedStatutJuridique, setSelectedStatutJuridique] =
    React.useState("");

  React.useEffect(() => {
    requestHelper
      .doRequest(_ACTIONS.GET, "/api/statut_juridiques")
      .then((response) => {
        setStatutJuridiques(response.data["hydra:member"]);
      });
  }, []);

  useEffect(() => {
    if (!edit) {
      reset();
      setAgence(null);
    } else {
      setSelectedStatutJuridique(edit.statutJuridique["@id"]);
      setValue("statutJuridique", edit.statutJuridique["@id"]);
    }
  }, [edit]);

  const handleChangeStatutJuridique = (event) => {
    setSelectedStatutJuridique(event.target.value);
  };

  /**
   * Handles the form submission by sending a request to the server to create or update an agency.
   * @param {Object} data - The form data to be sent to the server.
   */
  const onSubmit = (data) => {
    let mode = edit ? "edit" : "create";
    data = getQueryParamsFromFormData(data, mode);
    const method = edit ? _ACTIONS.PUT : _ACTIONS.POST;
    // restriction pour les utilisateurs
    const listeAgenceUser = user?.agences;
    if (user.profil !== "SADMIN") {
      if (listeAgenceUser) {
        data["id[]"] = listeAgenceUser.map((agence) => agence.id);
      }
    }

    requestHelper
      .doRequest(
        method,
        edit ? "/api/agences/" + edit.id : "/api/agences",
        data
      )
      .then((response) => {
        setRefresh(refresh + 1);
        setShowModal(false);
        if (response.data["@id"]) {
          createToast(
            _SWAL_ICONS.success,
            edit
              ? _MESSAGES.agence_edit_success
              : _MESSAGES.agence_create_success
          ).then((r) => refreshListAgence());
        } else {
          createToast(
            _SWAL_ICONS.error,
            edit ? _MESSAGES.agence_edit_error : _MESSAGES.agence_create_error
          );
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* PART 1 */}
      <Box
        display={part1display}
        sx={{
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            id="nom"
            label="Nom de l'agence *"
            {...register("nom", { required: true })}
            helperText={
              errors.nom?.type === "required" && (
                <Typography sx={_STYLES.errorMessage}>
                  Le nom de l'agence est obligatoire
                </Typography>
              )
            }
          ></TextField>
        </Box>
        <Box sx={_STYLES.boxInputRow}>
          <FormControl sx={_STYLES.basicInput} size="small">
            <InputLabel size="small">Statut juridique de l'agence</InputLabel>
            <Select
              id="statutJuridique"
              label="Statut juridique de l'agence"
              {...register("statutJuridique", { required: true })}
              value={selectedStatutJuridique}
              onChange={handleChangeStatutJuridique}
              helperText="Statut juridique de l'agence"
            >
              {statutJuridiques.map((c, i) => (
                <MenuItem key={c.id} value={c["@id"]}>
                  {c.nom}
                </MenuItem>
              ))}
            </Select>
            {errors.statutJuridique?.type === "required" && (
              <Typography sx={_STYLES.errorMessage}>
                Le statut juridique est obligatoire
              </Typography>
            )}
          </FormControl>
        </Box>
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            id="raisonSociale"
            label="Raison sociale de l'agence *"
            {...register("raisonSociale", { required: true })}
            helperText={
              errors.raisonSociale?.type === "required" && (
                <Typography sx={_STYLES.errorMessage}>
                  La raison sociale de l'agence est obligatoire
                </Typography>
              )
            }
          ></TextField>
        </Box>
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            id="statutRepresentant"
            label="Statut du représentant *"
            {...register("statutRepresentant", { required: true })}
            helperText={
              errors.statutRepresentant?.type === "required" && (
                <Typography sx={_STYLES.errorMessage}>
                  Le statut du représentant est obligatoire (Gérant/Président)
                </Typography>
              )
            }
          ></TextField>
        </Box>
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            id="nomGerant"
            label="Nom du gérant de l'agence *"
            {...register("nomGerant", { required: true })}
            helperText={
              errors.nomGerant?.type === "required" && (
                <Typography sx={_STYLES.errorMessage}>
                  Le nom du gérant de l'agence est obligatoire
                </Typography>
              )
            }
          ></TextField>
        </Box>
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            id="adresse"
            label="Adresse de l'agence *"
            {...register("adresse", { required: true })}
            helperText={
              errors.adresse?.type === "required" && (
                <Typography sx={_STYLES.errorMessage}>
                  L'adresse de l'agence est obligatoire
                </Typography>
              )
            }
          ></TextField>
          <TextField
            sx={{ ..._STYLES.basicInput, ...{ width: "60%" } }}
            size="small"
            id="ville"
            label="Ville *"
            {...register("ville", { required: true })}
            helperText={
              errors.ville?.type === "required" && (
                <Typography sx={_STYLES.errorMessage}>
                  La ville de l'agence est obligatoire
                </Typography>
              )
            }
          ></TextField>
          <TextField
            sx={{ ..._STYLES.basicInput, ...{ width: "50%" } }}
            size="small"
            id="codePostal"
            label="CP *"
            {...register("codePostal", { required: true })}
            helperText={
              errors.codePostal?.type === "required" && (
                <Typography sx={_STYLES.errorMessage}>
                  Le code postal de l'agence est obligatoire
                </Typography>
              )
            }
          ></TextField>
        </Box>
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={{ width: "50%", margin: "0.5em" }}
            id="tel"
            size="small"
            label="Téléphone"
            {...register("tel", {
              validate: (value) => {
                let regex =
                  /[0]{1}[1-7]{1}.[0-9]{2}.[0-9]{2}.[0-9]{2}.[0-9]{2}/;
                return regex.test(value);
              },
            })}
            helperText={
              errors.tel?.type === "validate" && (
                <Typography sx={_STYLES.errorMessage}>
                  Le format doit être au format XX.XX.XX.XX.XX
                </Typography>
              )
            }
          ></TextField>
          <TextField
            sx={{ width: "50%", margin: "0.5em" }}
            id="email"
            size="small"
            label="Email de l'agence"
            {...register("email", {
              validate: (value) => value.includes("@"),
            })}
            helperText={
              <Box>
                {errors.email?.type === "validate" && (
                  <Typography sx={_STYLES.errorMessage}>
                    L'email n'est pas valide
                  </Typography>
                )}
              </Box>
            }
          ></TextField>
        </Box>
        <Box sx={_STYLES.boxInputRow}></Box>
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={{
              width: "50%",
              margin: "0.5em",
            }}
            size="small"
            id="carteT"
            label="Carte T"
            {...register("carteT")}
          ></TextField>
          <TextField
            sx={{
              width: "50%",
              margin: "0.5em",
            }}
            size="small"
            label="Attribué par"
            {...register("carteTDeliveredBy")}
          ></TextField>
        </Box>

        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={{
              width: "50%",
              margin: "0.5em",
            }}
            size="small"
            id="rcs"
            label="RCS"
            {...register("rcs")}
          ></TextField>
          <TextField
            sx={{
              width: "50%",
              margin: "0.5em",
            }}
            size="small"
            id="rcsVille"
            label="RCS Ville"
            {...register("rcsVille")}
          ></TextField>
        </Box>
        <Typography>1 / 2</Typography>
      </Box>
      {/* END PART 1 */}
      {/********************************** */}
      {/* PART 2 */}
      <Box
        display={part2display}
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            label="Nom de l'assureur"
            {...register("assureur.nom")}
          ></TextField>
        </Box>
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            multiline={true}
            rows={3}
            size="small"
            label="Adresse de l'assureur"
            {...register("assureur.adresse")}
          ></TextField>
        </Box>

        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            label="Nom du médiateur"
            {...register("mediateur.nom")}
          ></TextField>
        </Box>

        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            label="Mail du médiateur"
            {...register("mediateur.email")}
          ></TextField>
        </Box>

        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            label="Adresse du médiateur"
            {...register("mediateur.adresse")}
          ></TextField>
        </Box>
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            label="Site du médiateur"
            {...register("mediateur.site")}
          ></TextField>
        </Box>

        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            label="Nom du garant financier"
            {...register("garantNom")}
          ></TextField>
        </Box>

        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            label="Adresse du garant financier"
            {...register("garantAdresse")}
          ></TextField>
        </Box>

        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            label="Numéro TVA intra"
            {...register("tvaIntra")}
          ></TextField>
        </Box>
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            label="Code APE"
            {...register("codeApe")}
          ></TextField>
        </Box>
        <Box sx={_STYLES.boxInputRow}>
          <TextField
            sx={_STYLES.basicInput}
            size="small"
            label="Numéro d'agence"
            disabled={
              !(
                user.profil === "ADMIN" ||
                user.profil === "SADMIN" ||
                user.profil === "SUSER"
              )
            }
            {...register("numeroAgence", {
              required: true,
              validate: (v) => !isNaN(v),
              setValueAs: (v) => (v === "" && isNaN(v) ? null : parseInt(v)),
            })}
            helperText={
              <Box>
                {errors.numeroAgence?.type === "required" && (
                  <Typography sx={_STYLES.errorMessage}>
                    Le numéro d'agence est obligatoire
                  </Typography>
                )}
                {errors.numeroAgence?.type === "validate" && (
                  <Typography sx={_STYLES.errorMessage}>
                    Le numéro d'agence doit être numérique
                  </Typography>
                )}
              </Box>
            }
          ></TextField>
          <TextField
            sx={_STYLES.basicInput}
            disabled={
              !(
                user.profil === "ADMIN" ||
                user.profil === "SADMIN" ||
                user.profil === "SUSER"
              )
            }
            size="small"
            label="Dernier numéro de dossier"
            {...register("numeroDernier", {
              required: true,
              validate: (v) => _.isEmpty(v) || !isNaN(v),
              setValueAs: (v) => (v === "" && isNaN(v) ? null : parseInt(v)),
            })}
            helperText={
              <Box>
                {errors.numeroDernier?.type === "required" && (
                  <Typography sx={_STYLES.errorMessage}>
                    Le dernier numéro de dossier est obligatoire
                  </Typography>
                )}

                {errors.numeroDernier?.type === "validate" && (
                  <Typography sx={_STYLES.errorMessage}>
                    Le dernier numéro de mandat doit etre numerique
                  </Typography>
                )}
              </Box>
            }
          ></TextField>
        </Box>
        <Typography>2 / 2</Typography>
      </Box>

      <Box sx={_STYLES.boxSubmitModal}>
        <Box>
          <Button
            onClick={() => setShowModal(false)}
            sx={btn(_COLORS.light, _COLORS.ultraLight)}
            variant="contained"
          >
            Annuler
          </Button>
        </Box>
        <Box display={part1display}>
          <Button
            sx={btn(_COLORS.success, _COLORS.secondary)}
            onClick={() => {
              setPart1Display("none");
              setPart2Display("flex");
            }}
          >
            Suivant
          </Button>
        </Box>
        <Box display={part2display}>
          <Button
            sx={btn(_COLORS.yellow, _COLORS.secondary)}
            onClick={() => {
              setPart2Display("none");
              setPart1Display("flex");
            }}
          >
            Retour
          </Button>
        </Box>
        <Box display={part2display}>
          <Button
            type="submit"
            sx={btn(_COLORS.primary, _COLORS.primaryLight)}
            variant="contained"
            onClick={() =>
              !_.isEmpty(errors)
                ? createToast(
                    _SWAL_ICONS.error,
                    "Veuillez corriger les erreurs",
                    1000
                  ).then(() => {
                    setPart2Display("none");
                    setPart1Display("flex");
                  })
                : null
            }
          >
            Valider
          </Button>
        </Box>
      </Box>
    </form>
  );
}
